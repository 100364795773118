import vars from '../helpers';

const init = () => {
	const $steps = vars.getPage().find('.js-home-steps');
	const $step = vars.getPage().find('.js-home-step');
	const $cards = vars.getPage().find('.js-home-cards');

	let id;

	$step.on('mouseenter.home', (e) => {
		id = $(e.currentTarget).data('step');
		$cards
			.data('hover', id)
			.attr('data-hover', id);
		$steps
			.data('hover', id)
			.attr('data-hover', id);
	});

	$step.on('mouseleave.home', () => {
		$cards
			.data('hover', '0')
			.attr('data-hover', '0');
		$steps
			.data('hover', '0')
			.attr('data-hover', '0');
	});
};

const destroy = () => {

};

export default {
	init,
	destroy,
};
