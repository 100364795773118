/* eslint-disable arrow-parens */
/* eslint-disable class-methods-use-this */
const dropdownObject = [];
const inputsRadio = [];

export class Dropdown {
	constructor(el) {
		this.wrapper = el.querySelector('.js-dropdown-wrapper');
		this.changeResult = el.querySelector('.js-dropdown-result');
		this.btn = el.querySelector('.js-dropdown-btn');
		this.content = el.querySelector('.js-dropdown-content');
		this.open = false;
		this.btn.addEventListener('click', this.handler.bind(this));
		this.update();
	}

	update() {
		if (this.content.clientHeight <= this.wrapper.clientHeight) {
			this.btn.style.display = 'none';
			this.wrapper.classList.add('disabled');
		}
	}

	handler() {
		if (!this.open) {
			this.wrapper.style.height = `${this.content.clientHeight}px`;
			this.open = true;
			this.btn.classList.add('open');
		} else {
			this.wrapper.style.height = 0;
			this.open = false;
			this.btn.classList.remove('open');
		}
	}

	destroy() {
		this.btn.removeEventListener('click', this.handler);
		this.wrapper = null;
		this.btn = null;
		this.content = null;
		this.open = false;
	}
}

function toggleResult(e) {
	const parent = e.currentTarget.closest('.js-dropdown');

	if (parent) {
		parent.querySelector('.js-dropdown-result').textContent =
			e.currentTarget.value;

		dropdownObject[0].handler();
	}
}

function init() {
	document.querySelectorAll('.js-dropdown').forEach((el) => {
		dropdownObject.push(new Dropdown(el));
	});

	document.querySelectorAll('input[type="radio"]').forEach((el) => {
		inputsRadio.push(el);
		el.addEventListener('change', toggleResult);
	});
}

function destroy() {
	dropdownObject.forEach((el) => {
		el.destroy();
	});

	dropdownObject.length = 0;

	inputsRadio.forEach((el) => {
		el.removeEventListener('change', toggleResult);
	});

	inputsRadio.length = 0;
}

export default {
	init,
	destroy,
	dropdownObject,
};
