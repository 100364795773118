/* eslint-disable arrow-parens */
const tabs = document.querySelector('.js-tabs');
let activeTab;
let activeContent;

function setTab(e) {
	const id = e.currentTarget.dataset.href;
	const content = tabs.querySelector(`[data-id="${id}"]`);
	const tab = e.currentTarget;

	if (activeContent) {
		activeContent.classList.remove('is-active');
	}

	content.classList.add('is-active');
	activeContent = content;

	if (activeTab) {
		activeTab.classList.remove('is-active');
	}

	tab.classList.add('is-active');
	activeTab = tab;
}

function init() {
	tabs.querySelectorAll('.js-tab').forEach(el => {
		el.addEventListener('click', setTab);
	});

	activeTab = tabs.querySelector('.js-tab.is-active');
	activeContent = tabs.querySelector('.career__tabs-content.is-active');
}

function destroy() {
	tabs.querySelectorAll('.js-tab').forEach(el => {
		el.removeEventListener('click', setTab);
	});
}

export default {
	init,
	destroy,
};

