import vars from '../helpers';
import aos from './aos';
import steps from './steps';

const init = () => {
	const $link = vars.getPage().find('.js-home-link');

	$link.on('click.home', (e) => {
		const link = $(e.currentTarget).data('link');

		gsap.to(window, {
			duration: 0.5,
			ease: 'none',
			scrollTo: {
				y: `#home-section[data-section="${link}"]`,
			},
		}, 0.5);
	});

	vars.$html.on('home-destroy', () => {
		$link.off('.home');
	});

	if (vars.isMobile()) {
		return;
	}

	let leadLottieAnimation;

	leadLottieAnimation = lottieWeb.loadAnimation({
		container: document.getElementById('lottie-lead'),
		path: '/static/js/lottie/lead.json',
		renderer: 'svg',
		loop: false,
		autoplay: false,
		name: 'Lead Lottie Animation',
	});

	leadLottieAnimation.setSpeed(2);

	aos.refresh();

	vars.$document.on('aos:in:figure-lead.home', () => {
		setTimeout(() => {
			leadLottieAnimation.play();
		}, 1000);
	});

	vars.$document.on('home-destroy', () => {
		leadLottieAnimation.destroy();
	});

	vars.$document.on('aos:in:figure-1.home', () => {
		const $figure = vars.getPage().find('.js-section-2-figure');
		const $img = $figure.find('.js-section-2-figure-4');

		const tl = gsap.timeline();

		let duration = 0.75;
		let delay = 0.5;

		for (let i = 1; i < 7; i++) {
			let $pic = $figure.find(`.js-section-2-figure-2${i}`);

			tl.from($pic, {
				onStart() {
					$pic.removeClass('is-hidden');
				},
				autoAlpha: 0,
				duration,
				clearProps: 'all',
			}, delay + duration / 2 * (i - 1) - i * 0.25);
		}

		tl
			.from($img, {
				onStart() {
					$img.removeClass('is-hidden');
				},
				autoAlpha: 0,
				duration,
				clearProps: 'all',
			}, delay + duration / 2 * 6 - 7 * 0.25)
		;

		vars.$html.on('home-destroy', () => {
			for (let i = 1; i < 7; i++) {
				let $pic = $figure.find(`.js-section-2-figure-2${i}`);

				$pic.addClass('is-hidden');
			}

			$img.addClass('is-hidden');

			vars.$document.off('.home');
		});
	});

	vars.$document.on('aos:in:cards.home', ({detail}) => {
		const $cards = $(detail);
		const $images = $cards.find('.js-home-cards-image');
		const $pic = $cards.find('.js-home-cards-pic-front');
		const $picBack = $cards.find('.js-home-cards-pic-back');
		const $content = $cards.find('.js-home-cards-content');
		const $steps = vars.getPage().find('.js-home-step');
		const $more = vars.getPage().find('.js-home-more-cards');

		const tl = gsap.timeline();

		let duration = 0.75;
		let delay = 0.5;

		tl
			.from($images, {
				autoAlpha: 0,
				translateX: 0,
				duration,
				clearProps: 'all',
			}, delay)
			.from([$pic, $content], {
				autoAlpha: 0,
				duration,
				clearProps: 'all',
			}, delay)
			.to($picBack, {
				onComplete() {
					$picBack.addClass('is-hidden');
				},
				autoAlpha: 0,
				duration,
				clearProps: 'all',
			}, delay)
			.from([$steps, $more], {
				onStart() {
					steps.init();
				},
				autoAlpha: 0,
				duration,
				clearProps: 'all',
			}, delay + duration)
		;

		vars.$html.on('home-destroy', () => {
			$picBack.removeClass('is-hidden');
		});
	});
};

const destroy = () => {
	vars.$html.trigger('home-destroy');
};

export default {
	init,
	destroy,
};
