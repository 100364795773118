import vars from '../helpers';

const init = () => {
	const $container = vars.getPage().find('.js-faq-container');
	const $items = $container.find('.js-faq-item');
	const $chapters = $container.find('.js-faq-chapter');
	const $sections = $container.find('.js-faq-section');
	const $answers = $container.find('.js-faq-answer');
	const $results = $container.find('.js-search-results');
	const $back = $container.find('.js-faq-answer-back');
	const $text = $container.find('.js-faq-text');

	if (!vars.isMobile()) {
		$container.find('.js-faq-answer[data-chapter="1"][data-section="1"]').removeClass('is-hidden');
		$container.find('.js-faq-section[data-chapter="1"][data-section="1"]').addClass('isActive');
	}

	$chapters.on('click.faq', (e) => {
		const $chapter = $(e.currentTarget);
		const $item = $chapter.closest('.js-faq-item');
		const chapter = $item.data('chapter');

		vars.addClass($results, 'is-hidden');

		if ($item.hasClass('isActive')) {
			$item.removeClass('isActive');

			if (vars.isMobile()) {
				vars.removeClass($sections, 'isActive');
				vars.addClass($text, 'is-hidden');
			}
		} else {
			vars.removeClass([$items, $sections], 'isActive');

			$item.addClass('isActive');
			vars.addClass($answers, 'is-hidden');

			if (!vars.isMobile()) {
				$container.find(`.js-faq-answer[data-chapter="${chapter}"][data-section="1"]`).removeClass('is-hidden');
				$container.find(`.js-faq-section[data-chapter="${chapter}"][data-section="1"]`).addClass('isActive');
			}
		}
	});

	const selectSection = (e) => {
		const $section = $(e.currentTarget);
		const chapter = $section.data('chapter');
		const section = $section.data('section');
		const $item = $container.find(`.js-faq-item[data-chapter="${chapter}"]`);

		vars.addClass($results, 'is-hidden');

		if (!$section.hasClass('isActive')) {
			vars.removeClass([$items, $sections], 'isActive');
			vars.addClass($item, 'isActive');
			vars.addClass($answers, 'is-hidden');

			$container.find(`.js-faq-answer[data-chapter="${chapter}"][data-section="${section}"]`).removeClass('is-hidden');
			$container.find(`.js-faq-section[data-chapter="${chapter}"][data-section="${section}"]`).addClass('isActive');
		}
	};

	$sections.on('click.faq', (e) => {
		if (!vars.isMobile()) {
			selectSection(e);
		} else {
			let $section = $(e.currentTarget);

			if ($section.hasClass('isActive')) {
				vars.removeClass($section, 'isActive');
				vars.addClass($section.closest('.js-faq-section-wrapper').find('.js-faq-text'), 'is-hidden');
			} else {
				vars.addClass($section, 'isActive');
				vars.removeClass($section.closest('.js-faq-section-wrapper').find('.js-faq-text'), 'is-hidden');
			}
		}
	});

	vars.$document.on('click', '.js-faq-result', (e) => {
		selectSection(e);
	});

	$back.on('click.faq', () => {
		vars.addClass($answers, 'is-hidden');
	});

	vars.$html.on('faq-destroy', () => {
		$chapters.off('.faq');
		$sections.off('.faq');
		$answers.off('.faq');

		vars.removeClass([$items, $sections], 'isActive');
	});

	vars.$html.on('faq-search', () => {
		vars.addClass($answers, 'is-hidden');
	});
};

const destroy = () => {
	vars.$html.trigger('faq-destroy');
};

export default {
	init,
	destroy,
};
