import './vendor';
import './helpers';
import './components/social';
import './components/analytics';
import './router';
import menu from './components/menu';

menu.init();

$(window).on('load', () => {
	$(document.documentElement).trigger('isLoad');
});