import vars from '../helpers';

const init = () => {
	const $search = vars.getPage().find('.js-search-container');
	const $input = $search.find('.js-search-input');
	const $submit = $search.find('.js-search-submit');
	const $faq = vars.getPage().find('.js-faq-container');
	const $answers = $faq.find('.js-faq-answer');
	const $results = $faq.find('.js-search-results');
	const $resultsContainer = $faq.find('.js-search-results-container');
	const $back = $faq.find('.js-faq-search-back');
	let isResult = false;

	const fillResults = (data) => {
		$resultsContainer.prepend(`
			<div class="faq__result-item">
				<div class="faq__result-title js-faq-result" data-chapter="${data.chapter}" data-section="${data.section}">${data.title}</div>
				<div class="faq__result-text" data-chapter="${data.chapter}" data-section="${data.section}">${data.text}</div>
			</div>
		`);

		isResult = true;
	};

	$input.on('focus.search', (e) => {
		if ($input[0].value.length) {
			$input.select();
		}
	});

	$submit.on('click.search', () => {
		$resultsContainer.html('');
		const val = $input[0].value.replace(/\s+/g, '');

		if (val.length > 0) {
			const search = (element) => {
				$answers.each((i, answer) => {
					let string = $(answer).find(`.js-faq-answer-${element}`).text().replace(/\s+/g, '');
					let data;

					if (string.indexOf(val) !== -1) {
						let title = $(answer).find('.js-faq-answer-title').text();
						let text = $(answer).find('.js-faq-answer-text').text();

						data = {
							chapter: $(answer).data('chapter'),
							section: $(answer).data('section'),
							title,
							text,
						};

						if (element === 'title') {
							data.title = title.replace($input[0].value, `<span class="yellow">${$input[0].value}</span>`);
						} else if (element === 'text') {
							data.text = text.replace($input[0].value, `<span class="yellow">${$input[0].value}</span>`);
						}

						fillResults(data);
					}
				});
			};

			search('title');
			search('text');

			if (!isResult) {
				$resultsContainer.prepend(`
					<div class="faq__result-item">
						<div class="faq__result-title">По вашему запросу ничего не найдено.</div>
					</div>
				`);

				isResult = false;
			}

			vars.$html.trigger('faq-search');
			$results.removeClass('is-hidden');
		}
	});

	$back.on('click.search', () => {
		$results.addClass('is-hidden');
	});

	vars.$html.on('search-destroy', () => {
		$submit.off('.search');
		$back.off('.search');

		$resultsContainer.html('');
		$results.addClass('is-hidden');
	});
};

const destroy = () => {
	vars.$html.trigger('search-destroy');
};

export default {
	init,
	destroy,
};
