import vars from '../helpers';

const validation = () => {
	let $form = vars.getPage().find('.js-blog-form');
	let $content = vars.getPage().find('.js-blog-form-content');
	let $success = vars.getPage().find('.js-blog-form-success');
	let $input = vars.getPage().find('.js-blog-form-input');
	let $email = vars.getPage().find('.js-blog-form-email');

	let rules = {
		emailBlog: {
			required: true,
			email: true,
		},
		emailArticle1: {
			required: true,
			email: true,
		},
		emailArticle2: {
			required: true,
			email: true,
		},
	};

	let messages = {
		emailBlog: {
			required: 'Поле обязательно к заполнению',
			email: 'Введите верный email',
		},
		emailArticle1: {
			required: 'Поле обязательно к заполнению',
			email: 'Введите верный email',
		},
		emailArticle2: {
			required: 'Поле обязательно к заполнению',
			email: 'Введите верный email',
		},
	};

	$form.validate({
		errorElement: 'span',
		rules,
		messages,
		invalidHandler: (e, validator) => {

		},
		submitHandler: () => {
			$.ajax({
				type: 'POST',
				url: '/ajax/blog.php',
				data: $form.serialize(),
				dataType: 'json',
			}).done((data) => {
				console.log(data);

				if (data.status === 'success') {
					const email = $input[0].value;
					const tl = gsap.timeline();

					tl
						.to($content, {
							onComplete() {
								$email.text(email);
								$content.addClass('is-hidden');
								$form.get(0).reset();
							},
							autoAlpha: 0,
							duration: 0.5,
							clearProps: 'all',
						}, 0)
						.from($success, {
							onStart() {
								$success.removeClass('is-hidden');
							},
							autoAlpha: 0,
							duration: 0.5,
							clearProps: 'all',
						}, 0.5)
					;
				}
			});

			return false;
		},
	});

	vars.$html.on('blog-form-clean', () => {
		$content.removeClass('is-hidden');
		$success.addClass('is-hidden');
		$email.text('');
	});
};

const clean = () => {
	vars.$html.trigger('blog-form-clean');
};

export default {
	clean,
	validation,
};