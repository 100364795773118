import vars from '../helpers';

const init = (tl) => {
	let preloaderLottieAnimation;
	
	preloaderLottieAnimation = lottieWeb.loadAnimation({
		container: document.getElementById('lottie-preloader'),
		path: '/static/js/lottie/preloader.json',
		renderer: 'svg',
		loop: true,
		autoplay: false,
		name: 'Preloader Lottie Animation',
	});
	
	preloaderLottieAnimation.setSpeed(2);
	preloaderLottieAnimation.play();

	tl.to(vars.$preloader, {
		duration: 0.5,
		autoAlpha: 0,
		onComplete() {
			vars.$html.removeClass('is-preloader-shown');
			vars.$preloader.addClass('is-hidden');
			preloaderLottieAnimation.destroy();
			vars.$html.off('.preloader');
		},
		clearProps: 'all',
	}, 0);
};

const destroy = () => {
	// vars.$html.trigger('preloader-destroy');
};

export default {
	init,
	destroy,
};
