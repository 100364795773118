import 'core-js/stable';
import 'regenerator-runtime/runtime';
import svg4everybody from 'svg4everybody';
import $ from 'jquery';
import gsap from 'gsap';
import lottieWeb from 'lottie-web';
// import CustomEase from 'gsap/CustomEase';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import AOS from 'aos';
import 'slick-carousel';
import 'jquery-validation';
import 'jquery-mask-plugin';

// import objectFitImages from 'object-fit-images';
// import objectFitVideos from 'object-fit-videos';

// gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

svg4everybody();

// objectFitImages();
// objectFitVideos();

window.$ = $;
window.jQuery = $;
window.gsap = gsap;
window.lottieWeb = lottieWeb;
// window.CustomEase = CustomEase;
window.ScrollToPlugin = ScrollToPlugin;
window.ScrollTrigger = ScrollTrigger;
window.AOS = AOS;

// window.objectFitImages = objectFitImages;
// window.objectFitVideos = objectFitVideos;

require('ninelines-ua-parser');
