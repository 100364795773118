import vars from '../helpers';

const validation = () => {
	let $vin = vars.getPage().find('.js-home-vin');
	let $ordinary = $vin.find('.js-home-vin-ordinary');
	let $digital = $vin.find('.js-home-vin-digital');
	let $error = $vin.find('.js-home-vin-error');
	let $wrapper = $vin.find('.js-home-vin-wrapper');
	let $additional = vars.getPage().find('.js-home-vin-additional');
	let $dig = $additional.find('.js-home-vin-dig');
	let $ord = $additional.find('.js-home-vin-ord');
	let $input = $vin.find('.js-home-vin-input');

	let rules = {
		vin: {
			required: true,
			minlength: 17,
		},
		vinEq: {
			required: true,
			minlength: 17,
		},
	};

	let messages = {
		vin: {
			required: 'Поле обязательно к заполнению',
			minlength: 'Введите верное значение',
		},
		vinEq: {
			required: 'Поле обязательно к заполнению',
			minlength: 'Проверьте VIN',
		},
	};

	// console.log('validate', $vin);

	let isAnswer = false;

	$vin.validate({
		errorElement: 'span',
		rules,
		messages,
		invalidHandler: (e, validator) => {

		},
		submitHandler: () => {
			$.ajax({
				type: 'POST',
				url: '/ajax/vin.php',
				data: $vin.serialize(),
				dataType: 'json',
			}).done((data) => {
				console.log(data);

				if ($wrapper.hasClass('is-hidden')) {
					$wrapper.removeClass('is-hidden');
				}

				if (data.digital === 'true') {
					$digital.removeClass('is-hidden');

					if ($additional.length) {
						$additional.removeClass('is-hidden');
						$dig.removeClass('is-hidden');
					}
				} else if (data.digital === 'false') {
					$ordinary.removeClass('is-hidden');

					if ($additional.length) {
						$additional.removeClass('is-hidden');
						$ord.removeClass('is-hidden');
					}
				} else if (data.digital === 'error') {
					$error.removeClass('is-hidden');
				}

				isAnswer = true;

				vars.$html.trigger('an-send-vin-form');
			});

			return false;
		},
	});

	$input.on('input.home', () => {
		if (!isAnswer) {
			return;
		}

		isAnswer = false;

		const hide = ($obj) => {
			if (!$obj.hasClass('is-hidden')) {
				$obj.addClass('is-hidden');
			}
		};

		hide($wrapper);
		hide($digital);
		hide($ordinary);
		hide($error);
		hide($additional);
		hide($dig);
		hide($ord);
	});
};

export default {
	validation,
};
