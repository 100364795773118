import vars from '../helpers';

// eslint-disable-next-line consistent-return
function getVacancy() {
	const vacansyInput = $('.js-dropdown-result');

	if (vacansyInput.length) {
		return vacansyInput[0].innerText;
	}
}

$.validator.addMethod(
	'checkText',
	function(value, element, regexp) {
		var re = new RegExp(regexp);
		return this.optional(element) || re.test(value);
	},
	'Введите верное значение',
);

const validation = () => {
	let $form = vars.getPage().find('.js-home-form');
	let $name = $form.find('.js-home-name');
	let $phone = $form.find('.js-home-phone');
	let $file = $form.find('.js-home-file');
	// let $query = $form.find('.js-home-file-query');
	let $fileContainer = $form.find('.js-home-file-container');
	let $fileName = $form.find('.js-home-file-name');
	// let $inputs = $form.find('input');
	// let $empty = $form.find('.js-form-message-empty');
	// let $incorrect = $form.find('.js-form-message-incorrect');
	let $container = $form.find('.js-home-form-container');
	let $success = $form.find('.js-home-form-success');
	let $remove = $form.find('.js-home-file-remove');

	let rules = {
		firstnameHome: {
			required: true,
			minlength: 3,
			checkText: '^[А-Яа-яЁёa-zA-Z\s]+$',
		},
		emailHome: {
			required: true,
			email: true,
		},
		phoneHome: {
			minlength: 18,
			required: true,
		},
		textHome: {
		},
		checkHome: {
			required: true,
		},
		firstnameEq: {
			required: true,
			minlength: 3,
			checkText: '^[А-Яа-яЁёa-zA-Z\s]+$',
		},
		emailEq: {
			required: true,
			email: true,
		},
		phoneEq: {
			minlength: 18,
			required: true,
		},
		textEq: {
		},
		checkEq: {
			required: true,
		},
		firstnamePartners: {
			required: true,
			minlength: 3,
			checkText: '^[А-Яа-яЁёa-zA-Z\s]+$',
		},
		emailPartners: {
			required: true,
			email: true,
		},
		phonePartners: {
			minlength: 18,
			required: true,
		},
		textPartners: {
		},
		checkPartners: {
			required: true,
		},
		firstnameContacts: {
			required: true,
			minlength: 3,
			checkText: '^[А-Яа-яЁёa-zA-Z\s]+$',
		},
		emailContacts: {
			required: true,
			email: true,
		},
		phoneContacts: {
			minlength: 18,
			required: true,
		},
		textContacts: {
		},
		checkContacts: {
			required: true,
		},
		firstnameCareer: {
			required: true,
			minlength: 3,
			checkText: '^[А-Яа-яЁёa-zA-Z\s]+$',
		},
		emailCareer: {
			required: true,
			email: true,
		},
		phoneCareer: {
			minlength: 18,
			required: true,
		},
		checkCareer: {
			required: true,
		},
		vacancyCareer: {
			required: true,
		},
		summary: {
			required: true,
		},
	};

	let messages = {
		firstnameHome: {
			required: 'Поле обязательно к заполнению',
			minlength: 'Введите верное значение',
		},
		phoneHome: {
			required: 'Поле обязательно к заполнению',
			minlength: 'Введите правильный номер',
		},
		emailHome: {
			required: 'Поле обязательно к заполнению',
			email: 'Введите верный email',
		},
		textHome: {
		},
		checkHome: {
			required: 'Поле обязательно к заполнению',
		},
		firstnameEq: {
			required: 'Поле обязательно к заполнению',
			minlength: 'Введите верное значение',
		},
		phoneEq: {
			required: 'Поле обязательно к заполнению',
			minlength: 'Введите правильный номер',
		},
		emailEq: {
			required: 'Поле обязательно к заполнению',
			email: 'Введите верный email',
		},
		textEq: {
		},
		checkEq: {
			required: 'Поле обязательно к заполнению',
		},
		firstnamePartners: {
			required: 'Поле обязательно к заполнению',
			minlength: 'Введите верное значение',
		},
		phonePartners: {
			required: 'Поле обязательно к заполнению',
			minlength: 'Введите правильный номер',
		},
		emailPartners: {
			required: 'Поле обязательно к заполнению',
			email: 'Введите верный email',
		},
		textPartners: {
		},
		checkPartners: {
			required: 'Поле обязательно к заполнению',
		},
		firstnameContacts: {
			required: 'Поле обязательно к заполнению',
			minlength: 'Введите верное значение',
		},
		phoneContacts: {
			required: 'Поле обязательно к заполнению',
			minlength: 'Введите правильный номер',
		},
		emailContacts: {
			required: 'Поле обязательно к заполнению',
			email: 'Введите верный email',
		},
		textContacts: {
		},
		checkContacts: {
			required: 'Поле обязательно к заполнению',
		},
		firstnameCareer: {
			required: 'Поле обязательно к заполнению',
			minlength: 'Введите верное значение',
		},
		emailCareer: {
			required: 'Поле обязательно к заполнению',
			email: 'Введите верный email',
		},
		phoneCareer: {
			required: 'Поле обязательно к заполнению',
			minlength: 'Введите правильный номер',
		},
		checkCareer: {
			required: 'Поле обязательно к заполнению',
		},
		vacancyCareer: {
			required: 'Поле обязательно к заполнению',
		},
		summary: {
			required: 'Поле обязательно к заполнению',
		},
	};

	// let errors = [];

	$form.validate({
		errorElement: 'span',
		ignore: '',
		rules,
		messages,
		// eslint-disable-next-line no-unused-vars
		invalidHandler: (e, validator) => {

		},
		submitHandler: () => {
			let data = new FormData($form[0]);

			if ($file.length && $file[0].files.length) {
				console.log($file[0].files.length);
				data.append('file', $file[0].files[0]);
			}

			$.ajax({
				type: 'POST',
				url: '/ajax/send.php',
				data,
				processData: false,
				contentType: false,
				dataType: 'json',
			// eslint-disable-next-line no-shadow
			}).done((data) => {
				// eslint-disable-next-line no-console
				console.log(data);

				if (data.status === 'success') {
					vars.$document.trigger('form-success-send');

					const careerSuccess = $('.js-home-form-success').find('.js-career-vacancy');

					if (careerSuccess.length) {
						careerSuccess[0].innerText = getVacancy();
					}

					const tl = gsap.timeline();

					tl
						.to($container, {
							onComplete() {
								$container.addClass('is-hidden');
								$form.get(0).reset();

								vars.$html.trigger('an-send-contact-form');
							},
							autoAlpha: 0,
							duration: 0.5,
							clearProps: 'all',
						}, 0)
						.from($success, {
							onStart() {
								$success.removeClass('is-hidden');
							},
							autoAlpha: 0,
							duration: 0.5,
							clearProps: 'all',
						}, 0.5)
					;

					// errors = [];

					// if (!$empty.hasClass('is-hidden')) {
					// 	$empty.addClass('is-hidden');
					// }

					// if (!$incorrect.hasClass('is-hidden')) {
					// 	$incorrect.addClass('is-hidden');
					// }

					// animation.form();

					// if (!$error.hasClass('is-hidden')) { // eslint-disable-line
					// 	console.log('errors');
					// 	$error.addClass('is-hidden');
					// }
				} else {
					// eslint-disable-next-line no-console
					console.log('form error');

					if (typeof data.errors != "undefined") { // eslint-disable-line
						// eslint-disable-next-line no-alert
						alert(data.errors);
					}
				}
			});

			return false;
		},
	});

	// $inputs.on('input', () => {
	// 	console.log('change');
	// });



	if ($phone.length) {
		let isClick = false;

		if (!isClick) {	
			$phone.on('click.isClick', () => {
				$phone[0].value='+7 (';
				isClick = true;
				$phone.off('.isClick');
			});
		}

		$phone.mask('+7 (999) 999-99-99');
	}

	if ($file.length) {
		$file.on('input', () => {
			$fileContainer.addClass('isUpload');
			$fileName.text('');
			$fileName.text($file[0].files[0].name);
		});

		$remove.on('click', () => {
			$fileContainer.removeClass('isUpload');
			$fileName.text('');
		});
	}

	vars.$html.on('form-clean', () => {
		$container.removeClass('is-hidden');
		$success.addClass('is-hidden');
	});
};

const clean = () => {
	vars.$html.trigger('form-clean');
};

export default {
	clean,
	validation,
};

// var validator = $( "#myform" ).validate();
// validator.resetForm();
