import vars from '../helpers';

const init = () => {
	// if (vars.isMobile()) {
	// 	return;
	// }

	let $header = vars.$document.find('.js-header');

	// if (vars.$html.data('page') === 'equipment') {
	// 	$header = vars.getPage().find('.js-equipment-header');
	// }

	// console.log(vars.getPage(), vars.$html.data('page'));

	const st = ScrollTrigger;
	const start = `${$header[0].offsetHeight}px`;

	st.create({
		// markers: true,
		trigger: vars.$body,
		start,
		end: 'bottom',
		onEnter: () => {
			$header.addClass('isFixed');
		},
		onEnterBack: () => {
			$header.removeClass('isFixed');
		},
		onUpdate: (
			(self) => {
				if (self.direction === 1) {
					$header.removeClass('isShown');
				} else {
					$header.addClass('isShown');
				}
			}
		),
	});

	vars.$html.on('scroll-destroy', () => {
		st.update();
		$header.removeClass('isFixed');
		$header.removeClass('isShown');
	});
};

const destroy = () => {
	vars.$html.trigger('scroll-destroy');
};

export default {
	init,
	destroy,
};
