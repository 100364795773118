import vars from '../helpers';

const checker = () => {
	const $aos = $('[data-aos]');

	$aos.each((i, e) => {
		if (!$(e).data('aos-delay')) {
			return;
		}

		if ($(e).offset().top > window.innerHeight) {
			$(e).attr('data-aos-delay', '0');
		}
	});
};

const init = () => {
	AOS.init({
		disable: innerWidth <= 1024,
		duration: 750,
		once: true,
	});
};

const refresh = () => {
	$('.aos-animate').removeClass('aos-animate');

	setTimeout(() => {
		AOS.refreshHard();
	}, 50);

	setTimeout(() => {
		checker();
	}, 75);

	vars.$window.on('load.aos', AOS.refreshHard);

	vars.$html.on('aos-refresh.aos', () => {
		AOS.refresh();
	});
};

const destroy = () => {
	vars.$window.off('.aos');
};

const resize = () => {
	destroy();
	init();
};

export default {
	init,
	destroy,
	resize,
	refresh,
};
