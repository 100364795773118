import vars from '../helpers';

// let resizeTimeout = null;

const init = () => {
	const $page = vars.getPage();

	const $sliders = $page.find('.js-home-slider');

	$sliders.each((i, slider) => {
		let $slider = $(slider);

		if ($slider.hasClass('slick-initialized')) {
			return;
		}

		let id = $slider.data('slider');

		const mobileOnly = [
			'also',
			'soon',
			'timeline',
			'skills',
			'contacts',
			'services',
			'career',
		];

		if (!vars.isMobile() && mobileOnly.indexOf(id) !== -1) {
			return;
		}

		// eslint-disable-next-line
		// if (id === 'soon' && !vars.isMobile() || id === 'timeline' && !vars.isMobile() || id === 'skills' && !vars.isMobile() || id === 'contacts' && !vars.isMobile() || id === 'services' && !vars.isMobile()) {
		// 	return;
		// }

		let params;

		if (id === 'belt') {
			params = {
				arrows: false,
				slidesToShow: 6,
				autoplay: true,
				autoplaySpeed: 0,
				speed: 5000,
				cssEase: 'linear',
				infinite: true,
				focusOnSelect: false,
				pauseOnFocus: false,
				pauseOnHover: false,
				rtl: +$slider.data('belt') === 2,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 3,
						},
					},
				],
			};
		} else if (id === 'article') {
			params = {
				arrows: true,
				dots: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				speed: 500,
				cssEase: 'ease-out',
				infinite: true,
				variableWidth: true,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							arrows: false,
							dots: true,
							slidesToShow: 1,
							slidesToScroll: 1,
							autoplay: false,
							speed: 500,
							cssEase: 'ease-out',
							infinite: false,
							variableWidth: true,
						},
					},
				],
			};
		} else if (id === 'also') {
			params = {
				arrows: false,
				dots: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				speed: 500,
				cssEase: 'ease-out',
				infinite: false,
				variableWidth: true,
			};
		} else if (id === 'promo') {
			params = {
				arrows: false,
				dots: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				speed: 500,
				cssEase: 'ease-out',
				infinite: true,
				variableWidth: true,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							// infinite: false,
							variableWidth: true,
							dots: true,
						},
					},
				],
			};
		} else if (id === 'equipment') {
			params = {
				arrows: false,
				dots: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				speed: 500,
				cssEase: 'ease-out',
				infinite: true,
			};
		} else if (id === 'career') {
			params = {
				arrows: false,
				dots: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				speed: 1000,
				cssEase: 'ease-out',
				infinite: true,
				variableWidth: true,
			};
		} else if (id === 'soon') {
			params = {
				arrows: false,
				dots: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				speed: 1000,
				cssEase: 'linear',
				infinite: false,
				variableWidth: true,
			};
		} else if (id === 'services') {
			params = {
				arrows: false,
				dots: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				speed: 500,
				cssEase: 'linear',
				infinite: false,
				// variableWidth: true,
			};
		} else if (id === 'timeline') {
			params = {
				arrows: false,
				dots: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				speed: 500,
				cssEase: 'linear',
				infinite: false,
			};
		} else if (id === 'skills') {
			params = {
				arrows: false,
				dots: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				autoplaySpeed: 0,
				speed: 500,
				cssEase: 'linear',
				infinite: false,
				focusOnSelect: false,
				pauseOnFocus: false,
				pauseOnHover: false,
				variableWidth: true,
			};
		} else if (id === 'works') {
			params = {
				arrows: false,
				dots: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				speed: 333,
				cssEase: 'linear',
				infinite: true,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							infinite: false,
							variableWidth: true,
						},
					},
				],
			};
		} else if (id === 'contacts') {
			params = {
				arrows: false,
				dots: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				speed: 1000,
				cssEase: 'linear',
				infinite: false,
			};
		}

		$slider.slick(params);

		if (id === 'promo') {
			const $images = $slider.closest('.js-home-slider-container').find('.js-home-slider-images');
			// const $toolbar = $page.find('.js-toolbar');
			// const $items = $toolbar.find('.js-toolbar-item');

			// $slider.on('beforeChange', (e, slick, currentSlide, nextSlide) => {
			// 	$toolbar.find(`.js-toolbar-item[data-item="${+currentSlide + 1}"]`).removeClass('is-active');
			// 	$toolbar.find(`.js-toolbar-item[data-item="${+nextSlide + 1}"]`).addClass('is-active');
			// 	$images.find(`.js-home-slider-image[data-image="${+currentSlide + 1}"]`).removeClass('is-active');
			// 	$images.find(`.js-home-slider-image[data-image="${+nextSlide + 1}"]`).addClass('is-active');
			// });

			$slider.slick('slickGoTo', 0);

			// $items.on('click', (e) => {
			// 	let num = $(e.currentTarget).data('item') - 1;

			// 	$slider.slick('slickGoTo', num);
			// });

			// $items.on('click', (e) => {
			// 	let num = $(e.currentTarget).data('item') - 1;

			// 	$slider.slick('slickGoTo', num);
			// });

			const $prev = $slider.closest('.js-home-slider-container').find('.js-home-slider-prev');
			const $next = $slider.closest('.js-home-slider-container').find('.js-home-slider-next');

			$prev.on('click', () => {
				$slider.slick('slickPrev');
			});

			$next.on('click', () => {
				$slider.slick('slickNext');
			});
		} else if (id === 'equipment') {
			$slider.slick('slickGoTo', 0);
			const $prev = $slider.closest('.js-home-slider-container').find('.js-home-slider-prev');
			const $next = $slider.closest('.js-home-slider-container').find('.js-home-slider-next');

			$prev.on('click', () => {
				$slider.slick('slickPrev');
			});

			$next.on('click', () => {
				$slider.slick('slickNext');
			});
		} else if (id === 'career') {
			const $images = $slider.closest('.js-home-slider-container').find('.js-home-slider-images');

			$slider.slick('slickGoTo', 0);

			const $prev = $slider.closest('.js-home-slider-container').find('.js-home-slider-prev');
			const $next = $slider.closest('.js-home-slider-container').find('.js-home-slider-next');

			$prev.on('click', () => {
				$slider.slick('slickPrev');
			});

			$next.on('click', () => {
				$slider.slick('slickNext');
			});
		} else if (id === 'timeline') {
			const $controls = $page.find('.js-timeline-controls');
			const $control = $page.find('.js-timeline-control');

			$slider.on('beforeChange', (e, slick, currentSlide, nextSlide) => {
				let next = +nextSlide + 1;

				if (next === 4) {
					next = 3;
				} else if (next === 5) {
					next = 4;
				}

				$control.removeClass('is-active');
				$controls.find(`.js-timeline-control[data-control="${next}"]`).addClass('is-active');
			});

			$slider.slick('slickGoTo', 0);

			$control.on('click', (e) => {
				let num = $(e.currentTarget).data('control');

				if (num === 4) {
					num = 5;
				}

				$slider.slick('slickGoTo', num - 1);
			});
		} else if (id === 'works') {
			const $images = $slider.closest('.js-home-slider-container').find('.js-home-slider-images');

			$slider.on('beforeChange', (e, slick, currentSlide, nextSlide) => {
				$images.find(`.js-home-slider-image[data-image="${+currentSlide + 1}"]`).removeClass('is-active');
				$images.find(`.js-home-slider-image[data-image="${+nextSlide + 1}"]`).addClass('is-active');
			});

			$slider.slick('slickGoTo', 0);

			const $prev = $slider.closest('.js-home-slider-container').find('.js-home-slider-prev');
			const $next = $slider.closest('.js-home-slider-container').find('.js-home-slider-next');

			$prev.on('click', () => {
				$slider.slick('slickPrev');
			});

			$next.on('click', () => {
				$slider.slick('slickNext');
			});
		} else if (id === 'contacts') {
			const $items = $slider.closest('.js-home-slider-container').find('.js-home-slider-items');
			const $item = $slider.closest('.js-home-slider-container').find('.js-home-slider-item');

			$slider.on('beforeChange', (e, slick, currentSlide, nextSlide) => {
				$items.find(`.js-home-slider-item[data-item="${+currentSlide + 1}"]`).removeClass('is-active');
				$items.find(`.js-home-slider-item[data-item="${+nextSlide + 1}"]`).addClass('is-active');
			});

			$slider.slick('slickGoTo', 0);

			$item.on('click', (e) => {
				let num = $(e.currentTarget).data('item') - 1;

				$slider.slick('slickGoTo', num);
			});
		}

		// $slider.on('beforeChange', (e, slick, currentSlide, nextSlide) => {

		// }):

		// $slider.on('afterChange', () => {
		// 	if (id === 'video') {
		// 		ga('send', 'event', 'article', 'photo slide');
		// 	}
		// });

		// const $prev = $slider.closest('.js-anounce-slider-container').find('.js-anounce-slider-prev');
		// const $next = $slider.closest('.js-anounce-slider-container').find('.js-anounce-slider-next');

		// $prev.on('click', () => {
		// 	$slider.slick('slickPrev');
		// });

		// $next.on('click', () => {
		// 	$slider.slick('slickNext');
		// });

		vars.$html.on('slider-destroy.slider', () => {
			$slider.slick('unslick');

			vars.$html.off('.slider');
		});

		vars.$html.on('slider-update', (e, slide) => {
			$slider.slick('slickGoTo', slide);
		});
	});
};

const destroy = () => {
	vars.$html.trigger('slider-destroy');
};

const update = (slide) => {
	vars.$html.trigger('slider-update', slide);
};

// vars.$window.on('resize', () => {
// 	if (resizeTimeout) {
// 		clearTimeout(resizeTimeout);
// 	}

// 	resizeTimeout = setTimeout(() => {
// 		destroy();
// 		init();
// 	}, 500);
// });

export default {
	init,
	update,
	destroy,
};
