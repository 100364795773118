import {Router} from 'ninelines-router';
import vars from './helpers';

// import analytics from './components/analytics';
// import scrollDepth from 'scroll-depth';


import home from './components/home';
import faq from './components/faq';

import aos from './components/aos';
import preloader from './components/preloader';
import slider from './components/slider';
import scroll from './components/scroll';
import popup from './components/popup';
import menu from './components/menu';
import form from './components/form';
import vin from './components/vin';
import search from './components/search';
import equipment from './components/equipment';
import subscribe from './components/subscribe';

import dropdown from './components/dropdown';
import tabs from './components/tabs';
import scrollToAnchor from './modules/scrollToAnchor';

let router = new Router();

aos.init();
// menu.init();

const checkPreloader = (tl, state) => {
	if (state) {
		tl.play();
	} else {
		preloader.init(tl);
	
		vars.$html.on('isLoad', () => {
			tl.play();
		});
	}
};

const separatePageAnalitycs = (prevStateRoute) => {
	// if (prevStateRoute) {
	// 	analytics.start15SecTimeout();
	// 	analytics.virtualHit(location.pathname);
	// 	scrollDepth.reset();
	// }
};

router.addRoute({
	path: '/',
	name: 'home',
	onEnter(prevState, currentState) {
		return new Promise((resolve) => {
			vars.$html.data('page', 'home').attr('data-page', 'home');

			// let page = 'home';

			// vars.setMeta('/');

			const tl = gsap.timeline({paused: true});

			vars.gsapCleaner(vars.$siteContainer);

			tl.from(vars.$siteContainer, {
				onStart() {
					vars.$home.removeClass('is-hidden');
					vars.$siteContainer.removeClass('is-hidden');
					
					popup.init();
					home.init();

					aos.refresh();
					slider.init();
					form.validation();
					vin.validation();
					scroll.init();
					// analytics.info('init');

					if (currentState.query.section) {
						gsap.to(window, {
							duration: 0.5,
							ease: 'none',
							scrollTo: {
								y: `#home-section[data-section='${currentState.query.section}']`,
							},
							onComplete() {
								window.history.pushState('', '', '/');
							},
						}, 0.5);
					} else {
						window.scrollTo(0, 0);
					}
				},
				onComplete() {
					separatePageAnalitycs(prevState.route);

					resolve();
				},
				duration: 1,
				autoAlpha: 0,
				clearProps: 'all',
			}, prevState.route ? 0 : 0.5);

			checkPreloader(tl, prevState.route);

			// home.enter(tl, prevState.route);
		});
	},
	onLeave() {
		return new Promise((resolve) => {
			vars.gsapCleaner(vars.$siteContainer);

			gsap.to(vars.$siteContainer, {
				onComplete() {
					vars.$siteContainer.addClass('is-hidden');
					vars.$home.addClass('is-hidden');

					popup.destroy();
					home.destroy();
					menu.destroy();
					slider.destroy();
					scroll.destroy();

					// analytics.info('destroy');

					resolve();
				},
				duration: 0.5,
				autoAlpha: 0,
				clearProps: 'all',
			});
		});
	},
});

router.addRoute({
	path: '/faq',
	name: 'faq',
	onEnter(prevState) {
		return new Promise((resolve) => {
			vars.$html.data('page', 'faq').attr('data-page', 'faq');

			const tl = gsap.timeline();

			vars.gsapCleaner(vars.$siteContainer);

			tl.from(vars.$siteContainer, {
				onStart() {
					vars.$faq.removeClass('is-hidden');
					vars.$siteContainer.removeClass('is-hidden');

					aos.refresh();

					faq.init();
					search.init();

					window.scrollTo(0, 0);
				},
				onComplete() {
					separatePageAnalitycs(prevState.route);

					resolve();
				},
				duration: 1,
				autoAlpha: 0,
				clearProps: 'all',
			}, prevState.route ? 0 : 0.5);

			checkPreloader(tl, prevState.route);
		});
	},
	onLeave() {
		return new Promise((resolve) => {
			vars.gsapCleaner(vars.$siteContainer);

			gsap.to(vars.$siteContainer, {
				onComplete() {
					vars.$siteContainer.addClass('is-hidden');
					vars.$faq.addClass('is-hidden');

					faq.destroy();
					search.destroy();
					menu.destroy();

					resolve();
				},
				duration: 0.5,
				autoAlpha: 0,
				clearProps: 'all',
			});
		});
	},
});

router.addRoute({
	path: '/contacts',
	name: 'contacts',
	onEnter(prevState) {
		return new Promise((resolve) => {
			vars.$html.data('page', 'contacts').attr('data-page', 'contacts');

			const tl = gsap.timeline();

			vars.gsapCleaner(vars.$siteContainer);

			tl.from(vars.$siteContainer, {
				onStart() {
					vars.$contacts.removeClass('is-hidden');
					vars.$siteContainer.removeClass('is-hidden');

					aos.refresh();

					slider.init();
					form.validation();
					scroll.init();
					// contacts.init();

					window.scrollTo(0, 0);
				},
				onComplete() {
					separatePageAnalitycs(prevState.route);

					resolve();
				},
				duration: 1,
				autoAlpha: 0,
				clearProps: 'all',
			}, prevState.route ? 0 : 0.5);

			checkPreloader(tl, prevState.route);
		});
	},
	onLeave() {
		return new Promise((resolve) => {
			vars.gsapCleaner(vars.$siteContainer);

			gsap.to(vars.$siteContainer, {
				onComplete() {
					vars.$siteContainer.addClass('is-hidden');
					vars.$contacts.addClass('is-hidden');

					slider.destroy();
					menu.destroy();
					scroll.destroy();
					// contacts.destroy();

					resolve();
				},
				duration: 0.5,
				autoAlpha: 0,
				clearProps: 'all',
			});
		});
	},
});

router.addRoute({
	path: '/partners',
	name: 'partners',
	onEnter(prevState) {
		return new Promise((resolve) => {
			vars.$html.data('page', 'partners').attr('data-page', 'partners');

			const tl = gsap.timeline();

			vars.gsapCleaner(vars.$siteContainer);

			tl.from(vars.$siteContainer, {
				onStart() {
					vars.$partners.removeClass('is-hidden');
					vars.$siteContainer.removeClass('is-hidden');

					aos.refresh();
					slider.init();
					// partners.init();
					form.validation();
					scroll.init();

					window.scrollTo(0, 0);
				},
				onComplete() {
					separatePageAnalitycs(prevState.route);

					resolve();
				},
				duration: 1,
				autoAlpha: 0,
				clearProps: 'all',
			}, prevState.route ? 0 : 0.5);

			checkPreloader(tl, prevState.route);
		});
	},
	onLeave() {
		return new Promise((resolve) => {
			vars.gsapCleaner(vars.$siteContainer);

			gsap.to(vars.$siteContainer, {
				onComplete() {
					vars.$siteContainer.addClass('is-hidden');
					vars.$partners.addClass('is-hidden');

					slider.destroy();
					menu.destroy();
					scroll.destroy();
					// partners.destroy();

					resolve();
				},
				duration: 0.5,
				autoAlpha: 0,
				clearProps: 'all',
			});
		});
	},
});

router.addRoute({
	path: '/equipment',
	name: 'equipment',
	onEnter(prevState) {
		return new Promise((resolve) => {
			vars.$html.data('page', 'equipment').attr('data-page', 'equipment');

			const tl = gsap.timeline();

			vars.gsapCleaner(vars.$siteContainer);

			tl.from(vars.$siteContainer, {
				onStart() {
					vars.$equipment.removeClass('is-hidden');
					vars.$siteContainer.removeClass('is-hidden');

					aos.refresh();
					popup.init();
					slider.init();
					scroll.init();
					equipment.init();

					form.validation();
					vin.validation();

					window.scrollTo(0, 0);
				},
				onComplete() {
					separatePageAnalitycs(prevState.route);

					resolve();
				},
				duration: 1,
				autoAlpha: 0,
				clearProps: 'all',
			}, prevState.route ? 0 : 0.5);

			checkPreloader(tl, prevState.route);
		});
	},
	onLeave() {
		return new Promise((resolve) => {
			vars.gsapCleaner(vars.$siteContainer);

			gsap.to(vars.$siteContainer, {
				onComplete() {
					vars.$siteContainer.addClass('is-hidden');
					vars.$equipment.addClass('is-hidden');

					slider.destroy();
					menu.destroy();
					popup.destroy();
					scroll.destroy();
					equipment.destroy();

					resolve();
				},
				duration: 0.5,
				autoAlpha: 0,
				clearProps: 'all',
			});
		});
	},
});

router.addRoute({
	path: '/blog',
	name: 'blog',
	onEnter(prevState, currentState) {
		return new Promise((resolve) => {
			vars.$html.data('page', 'blog').attr('data-page', 'blog');

			const tl = gsap.timeline();

			vars.gsapCleaner(vars.$siteContainer);

			tl.from(vars.$siteContainer, {
				onStart() {
					vars.$blog.removeClass('is-hidden');
					vars.$siteContainer.removeClass('is-hidden');

					aos.refresh();
					scroll.init();
					subscribe.validation();

					window.scrollTo(0, 0);
				},
				onComplete() {
					separatePageAnalitycs(prevState.route);

					resolve();
				},
				duration: 1,
				autoAlpha: 0,
				clearProps: 'all',
			}, prevState.route ? 0 : 0.5);

			checkPreloader(tl, prevState.route);
		});
	},
	onLeave() {
		return new Promise((resolve) => {
			vars.gsapCleaner(vars.$siteContainer);

			gsap.to(vars.$siteContainer, {
				onComplete() {
					vars.$siteContainer.addClass('is-hidden');
					vars.$blog.addClass('is-hidden');

					menu.destroy();
					scroll.destroy();
					subscribe.clean();

					resolve();
				},
				duration: 0.5,
				autoAlpha: 0,
				clearProps: 'all',
			});
		});
	},
});

router.addRoute({
	path: '/blog/:id',
	name: 'article',
	onEnter(prevState, currentState) {
		return new Promise((resolve) => {
			const id = currentState.params.id;

			if (!vars.$document.find(`.js-article-page[data-article="${id}"]`).length) {
				router.navigate('/');
			}

			vars.$html.data('page', 'article').attr('data-page', 'article');
			vars.$html.data('article', id).attr('data-article', id);

			const $article = vars.$document.find(`.js-article-page[data-article="${id}"]`);
			const tl = gsap.timeline();

			// console.log(currentState.params.id);

			vars.gsapCleaner(vars.$siteContainer);

			tl.from(vars.$siteContainer, {
				onStart() {
					$article.removeClass('is-hidden');
					vars.$siteContainer.removeClass('is-hidden');

					aos.refresh();
					scroll.init();
					slider.init();
					subscribe.validation();

					window.scrollTo(0, 0);
				},
				onComplete() {
					separatePageAnalitycs(prevState.route);

					resolve();
				},
				duration: 1,
				autoAlpha: 0,
				clearProps: 'all',
			}, prevState.route ? 0 : 0.5);

			checkPreloader(tl, prevState.route);
		});
	},
	onLeave(currentState) {
		return new Promise((resolve) => {
			vars.gsapCleaner(vars.$siteContainer);

			const id = currentState.params.id;
			const $article = vars.$document.find(`.js-article-page[data-article="${id}"]`);

			gsap.to(vars.$siteContainer, {
				onComplete() {
					vars.$siteContainer.addClass('is-hidden');
					$article.addClass('is-hidden');

					menu.destroy();
					scroll.destroy();
					slider.destroy();
					subscribe.clean();

					resolve();
				},
				duration: 0.5,
				autoAlpha: 0,
				clearProps: 'all',
			});
		});
	},
});

router.addRoute({
	path: '/career',
	name: 'career',
	onEnter(prevState) {
		return new Promise((resolve) => {
			vars.$html.data('page', 'career').attr('data-page', 'career');

			const tl = gsap.timeline();

			vars.gsapCleaner(vars.$siteContainer);

			tl.from(vars.$siteContainer, {
				onStart() {
					// career.init();
					vars.$career.removeClass('is-hidden');
					vars.$siteContainer.removeClass('is-hidden');

					aos.refresh();
					
					dropdown.init();
					tabs.init();
					popup.init();
					form.validation();
					slider.init();
					scrollToAnchor.init();
					scroll.init();

					window.scrollTo(0, 0);
				},
				onComplete() {
					separatePageAnalitycs(prevState.route);

					resolve();
				},
				duration: 1,
				autoAlpha: 0,
				clearProps: 'all',
			}, prevState.route ? 0 : 0.5);

			checkPreloader(tl, prevState.route);
		});
	},
	onLeave() {
		return new Promise((resolve) => {
			vars.gsapCleaner(vars.$siteContainer);

			gsap.to(vars.$siteContainer, {
				onComplete() {
					vars.$siteContainer.addClass('is-hidden');
					vars.$career.addClass('is-hidden');

					// career.destroy();
					dropdown.destroy();
					tabs.destroy();
					popup.destroy();
					menu.destroy();
					scroll.destroy();

					resolve();
				},
				duration: 0.5,
				autoAlpha: 0,
				clearProps: 'all',
			});
		});
	},
});

router.on('notFound', () => {
	router.navigate('/');

	return Promise.resolve();
});

router.start();

export default router;
