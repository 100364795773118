import vars from '../helpers';
import form from './form';

const init = () => {
	const $page = vars.getPage();
	const $selectors = $page.find('.js-home-soon');
	const $popup = $page.find('.js-popup');
	const $overflow = $page.find('.js-popup-overflow');

	let id;
	let $item;
	let $close;
	let $careerBottomBtn;
	let popupTitle;
	let $popupTitle;

	$selectors.on('click.popup', (e) => {
		id = $(e.currentTarget).data('item');
		$item = $popup.find(`.js-popup-item[data-item=${id}]`);
		$close = $item.find('.js-popup-close');
		$careerBottomBtn = $item.find('.js-popup-career-btn');

		if (id === 'equipment') {
			popupTitle = $(e.currentTarget).text();
			$popupTitle = $item.find('.js-popup-title');
		}

		const openPopup = gsap.timeline({paused: true});

		openPopup
			.from($popup, {
				onStart() {
					if (id === 'equipment') {
						$popupTitle.text(popupTitle);
					}

					vars.$html.addClass('is-popup-open');
					$popup.removeClass('is-hidden');
					$item.removeClass('is-hidden');
				},
				autoAlpha: 0,
				duration: 0.5,
				clearProps: 'all',
			})
		;

		openPopup.play();

		const closePopup = gsap.timeline({paused: true});

		closePopup
			.to($popup, {
				onComplete() {
					$popup.addClass('is-hidden');
					$item.addClass('is-hidden');
					vars.$html.removeClass('is-popup-open');

					if (id === 'equipment') {
						form.clean();
					}
				},
				autoAlpha: 0,
				duration: 0.5,
				clearProps: 'all',
			})
		;

		$overflow.on('click.popup', () => {
			closePopup.play();
		});

		// eslint-disable-next-line no-shadow
		vars.$document.on('keydown.popup', (e) => {
			if (e.keyCode === 27) {
				closePopup.play();
			}
		});

		vars.$html.on('popup-destroy', () => {
			closePopup.play();

			vars.$document.off('.popup');
			$selectors.off('.popup');
			$close.off('.popup');
			$close.off('.popup');
			$overflow.off('.popup');

			if ($careerBottomBtn.length !== 0) {
				$careerBottomBtn.off('.popup');
			}
		});

		$close.on('click.popup', () => {
			closePopup.play();
		});

		if ($careerBottomBtn.length !== 0) {
			$careerBottomBtn.on('click.popup', () => {
				closePopup.play();

				setTimeout(() => {
					vars.scrollTo($('.career__section--3'));
				}, 500);
			});
		}
	});
};

const destroy = () => {
	vars.$html.trigger('popup-destroy');
};

export default {
	init,
	destroy,
};
