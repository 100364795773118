import vars from '../helpers';
import router from '../router';

const init = () => {
	// if (!vars.isMobile()) {
	// 	return;
	// }

	const $button = vars.$document.find('.js-header-menu-button');
	const $items = vars.$document.find('.js-header-menu-item');

	// const $header = vars.$document.find('.js-header');
	// const $menu = vars.$document.find('.js-menu');

	// vars.$window.on('scroll resize', () => {
	// 	const headerHeight = $header.height();

	// 	if (vars.$window.scrollTop() > headerHeight && !$menu.hasClass('is-shown')) {
	// 		$menu.addClass('is-shown');
	// 	}

	// 	if (vars.$window.scrollTop() < headerHeight && $menu.hasClass('is-shown')) {
	// 		$menu.removeClass('is-shown');
	// 	}
	// });

	$button.on('click.menu', () => {
		vars.$html.toggleClass('is-menu-open');
	});

	$items.on('click', (e) => {
		const page = $(e.currentTarget).data('page');

		if (page === vars.$html.data('page')) {
			vars.$html.removeClass('is-menu-open');
		} else {
			router.navigate(`/${page}`);
		}
	});

	vars.$html.on('menu-destroy', () => {
		vars.$html.removeClass('is-menu-open');

		// $menu.removeClass('is-shown');
	});
};

const destroy = () => {
	vars.$html.trigger('menu-destroy');
};

export default {
	init,
	destroy,
};
