import vars from '../helpers';

const init = () => {
	if (vars.isMobile()) {
		return;
	}

	vars.$document.on('aos:in:circles.eq', () => {
		const $figure = vars.getPage().find('.js-eq-figure');
		const $circle = $figure.find('.js-eq-figure-circle');
		const length = $figure.find('.js-eq-figure-image').length + 1;

		// const $circles = $figure.find('.js-eq-figure-image');

		const tl = gsap.timeline();

		let duration = 0.75;
		let delay = 0.75;


		tl
			.from($circle, {
				onStart() {
					$circle.removeClass('is-hidden');
				},
				autoAlpha: 0,
				duration,
				clearProps: 'all',
			}, delay / 3)
		;

		for (let i = 1; i < length; i++) {
			let $image = $figure.find(`.js-eq-figure-image[data-image="${i}"]`);

			tl.from($image, {
				onStart() {
					$image.removeClass('is-hidden');
				},
				autoAlpha: 0,
				duration,
				clearProps: 'all',
			}, delay + duration * (i - 1) - i * 0.25);
		}

		vars.$html.on('eq-destroy', () => {
			for (let i = 1; i < length; i++) {
				let $image = $figure.find(`.js-eq-figure-image[data-image="${i}"]`);

				$image.addClass('is-hidden');
			}

			$circle.addClass('is-hidden');

			vars.$document.off('.eq');
		});
	});
}

const destroy = () => {
	vars.$html.trigger('eq-destroy');
};

export default {
	init,
	destroy,
};
